<template>
    <div class="manageBox">
        <div class="top">
            <el-form :inline="true" :model="queryParams" label-width="100px" class="demo-form-inline">
                <el-form-item label="选择月份">
                    <el-date-picker v-model="queryParams.currentMonth" :clearable="false" value-format="yyyy-MM" type="month" placeholder="选择月"> </el-date-picker>
                </el-form-item>
                <el-form-item label="服务机构">
                    <el-select class="w350" v-model="queryParams.serviceId" placeholder="请选择服务机构" filterable clearable>
                        <el-option v-for="item in organList" :label="item.serviceName" :value="item.id" :key="item.id"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属区划">
                    <el-cascader
                        clearable
                        placeholder="请选择所属区划"
                        v-model="streetArr"
                        :props="defaultProps"
                        class="w350"
                        ref="supervisedUnitCode"
                        :show-all-levels="false"
                        filterable
                        :options="streetList"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="服务对象类别">
                    <el-autocomplete class="w350" v-model="queryParams.serviceObjectType" :fetch-suggestions="querySearchAsync" placeholder="请输入服务对象类别"></el-autocomplete>
                </el-form-item>
                <el-form-item label="关键字">
                    <el-input v-model="queryParams.keyWord" clearable placeholder="请输入关键字" style="width: 200px" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" class="search" @click="getList(1)">搜索</el-button>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button type="primary" class="add" @click="listDowExcel">导出</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="content">
            <el-table
                v-loading="loading"
                :data="tableData"
                border
                :row-style="{ height: '55px' }"
                style="width: 100%"
                :header-cell-style="headerCellStyle"
                :row-class-name="tableRowClassName"
                class="content-itme"
            >
                <template slot="empty">
                    <IsEmpty />
                </template>
                <el-table-column type="index" width="50" label="序号" />
                <el-table-column prop="userName" label="姓名" />
                <el-table-column prop="cardId" label="身份证号" />
                <el-table-column prop="phone" label="联系电话" />
                <el-table-column prop="orgName" label="所属区划" />
                <el-table-column prop="objectName" label="服务对象类别" />
                <el-table-column prop="objectElderlySubsidy" label="补贴标准（元/月）" />
                <el-table-column prop="monthServiceTime" label="月服务时长" />
                <el-table-column prop="monthServiceNumber" label="月服务次数（次）" />
                <el-table-column prop="monthServiceMoney" label="月服务金额（元）" />
                <el-table-column prop="serviceName" label="服务机构" />
            </el-table>
            <el-pagination
                style="margin: 20px 0"
                background
                layout="total, sizes, prev, pager, next"
                :total="total"
                :page-size="pageSize"
                :current-page="pageNum"
                :page-sizes="[10, 20, 30, 40]"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>

<script>
import { helpAgeServiceStatistics, listDowExcel } from '@/api/pensionService'
import { downloadStream } from '@/utils/index'
import { streetList, serviceNameList, backCascader2, getServiceObjectTypeList } from '@/api/orderManage'
export default {
    data() {
        return {
            total: 0,
            pageNum: 1,
            pageSize: 10,
            loading: false,
            options: [],
            tableData: [],
            streetList: [],
            streetArr: [],
            organList: [],
            comboOptions: [],
            queryParams: {
                currentMonth: '',
                keyWord: '',
                orgId: '',
                serviceId: '',
                serviceObjectType: ''
            },
            defaultProps: {
                value: 'streetId',
                label: 'streetName',
                children: 'streetNameListResultList',
                checkStrictly: true
            }
        }
    },
    mounted() {
        this.initDate()
        this.getStreetList()
        this.getOrganList()
        this.getServiceList()
        this.getList()
    },
    methods: {
        initDate() {
            let date = new Date()
            let nowYear = date.getFullYear()
            let nowMonth = date.getMonth() + 1
            if (nowMonth >= 1 && nowMonth <= 9) {
                nowMonth = '0' + nowMonth
            }
            this.queryParams.currentMonth = `${nowYear}-${nowMonth}`
        },
        // 管理人员列表
        async getList(num) {
            if (num) {
                this.pageNum = num
            }
            this.loading = true
            const data = {
                pageNum: this.pageNum,
                pageSize: this.pageSize
            }
            this.queryParams.orgId = this.streetArr[this.streetArr.length - 1]
            const res = await helpAgeServiceStatistics({
                ...data,
                ...this.queryParams
            })
            if (res.state === 0) {
                this.loading = false
                this.tableData = res.data.rows
                this.total = res.data.total
            }
        },
        async listDowExcel() {
            const data = {
                pageNum: this.pageNum,
                pageSize: this.pageSize
            }
            this.queryParams.orgId = this.streetArr[this.streetArr.length - 1]
            const res = await listDowExcel({ ...data, ...this.queryParams })
            downloadStream(res, `助老服务统计.xls`)
        },
        // 获取街道列表
        getStreetList() {
            streetList().then(res => {
                this.streetList = res.data
            })
        },
        // 获取机构列表
        getOrganList() {
            serviceNameList().then(res => {
                this.organList = res.data
            })
        },
        // 获取服务项目列表
        getServiceList() {
            backCascader2().then(res => {
                this.comboOptions = res.data
            })
        },
        // 翻页
        handleCurrentChange(val) {
            this.loading = true
            this.pageNum = val
            this.getList()
        },
        handleSizeChange(val) {
            this.loading = true
            this.pageSize = val
            this.getList()
        },
        // 表格样式
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 === 0) {
                return 'statistics-warning-row'
            } else {
                return ''
            }
        },
        querySearchAsync(queryString, cb) {
            let result = []
            getServiceObjectTypeList({ serviceObjectType: queryString }).then(res => {
                if (res.data.length) {
                    res.data.forEach(item => {
                        result.push({
                            value: item,
                            id: item
                        })
                    })
                }
            })
            cb(result)
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep {
    .roleColumn {
        max-width: 350px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inherit;
    }
}
.manageBox {
    /*margin: 20px;*/
    background: #fff;
    min-height: calc(100vh - 130px);
    .top {
        padding: 20px 15px;
        /*border-bottom: 2px solid #f5f7fa;*/
        .search {
            margin-left: 20px;
        }
        .add {
            float: right;
        }
    }
    .eyes {
        // display: inline-block;
        width: 20px;
        height: 20px;
        float: right;
        margin-right: 20px;
        cursor: pointer;
    }
    .operation {
        margin-right: 20px;
        color: #3fcf89;
        cursor: pointer;
    }
    .stop {
        color: #f57473;
    }
    .widthStyle {
        width: 90%;
    }
    /deep/ .el-dialog__title {
        font-size: 16px;
    }
    /deep/ .el-dialog__header {
        font-weight: 600;
        color: #333333;
    }
    /deep/ .el-dialog__body {
        padding-left: 40px;
    }
    /deep/ .el-dialog__footer {
        text-align: center;
        padding-left: 40px;
    }
}
.content {
    padding: 15px;
    padding-top: 20px;
}
.my_btn_duty {
    margin-right: 20px;
}
.el-button--text {
    outline: none;
    border: none;
}
.el-button--text:hover,
.el-button--text:focus {
    background: none !important;
    border: none !important;
    outline: none !important;
}
.el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus {
    color: #c0c4cc !important;
}
.w200px {
    width: 200px;
}
</style>
